import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Container from "./Container";
import ContainerHeader from "./ContainerHeader";
import ContainerContent from "./ContainerContent";
import SeoContent from "./seoContent";

export default function Policy(props) {
  const isSingleSiteEnvSetting = process.env.IS_SINGLE_PAGE_SITE;

  const isSingleSite = isSingleSiteEnvSetting === "true";

  return (
    <div className="leading-relaxed bg-primary tracking-wide flex flex-col font-custom min-h-screen">
      <SeoContent pageSeo={props.seo}/>
      <NavBar isSingleSite={isSingleSite}/>
      <Container>
        <ContainerHeader text={props.heading} />
        <ContainerContent text={props.content} />
      </Container>
      <Footer />
    </div>
  );
}

Policy.propTypes = {
  heading: "",
  content: "",
};
